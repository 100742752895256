
import { ref, onMounted, Ref, reactive } from "vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Api from "../../services/Api";
import Button from "@/components/Button.vue"
import Title from "@/components/Title.vue"

interface Credentials {
  client_id_camp: string;
  client_secret_camp: string;
  grant_type: string;
}
interface CredentialsPdv {
  client_id: string;
  client_secret: string;
  grant_type: string;
}

interface RegistraTerminalInput {
  codigoPontoVenda: number | null;
  codigoComponenteCorporativo: number | null;
  token: string
}


export default defineComponent({
  name: "GestaoApiConecta",
  components: {
    Button,
    Title
  },

  setup() {
    const router = useRouter();
    const client_id = ref('');
    const cnpjComponente: Ref<string> = ref("");
    const dadosPdv: Ref<any> = ref({});
    const showDadosPdv: Ref<boolean> = ref(false);
    const registraTerminalResponse: Ref<string> = ref("");
    const tokenPdv: Ref<string> = ref("");
    const tokenRegistraPdv: Ref<string> = ref("");
    const cnpjPdv: Ref<string> = ref("");
    const cpfConsumidor: Ref<string> = ref("")
    const dadosConsumidor: Ref<string> = ref("")
    const dadosCadastraConsumidor: Ref<string> = ref("")
    const responseCadastraConsumidor: Ref<string> = ref("")
    const responseConsultaOs: Ref<string> = ref("")
    const placaParaBuscaOs: Ref<string> = ref("")
    const dadosAtendimento: Ref<string> = ref("")
    const responseAbreAtendimento: Ref<string> = ref("")
    const dadosConfirmaPagamento: Ref<string> = ref("")
    const responseConfirmaPagamento: Ref<string> = ref("")
    const responseTokenRegistraPdv: Ref<string> = ref("")
    const responseTokenBackendCamp: Ref<string> = ref("")
    const loadingCredentialsGetTokenRegistra: Ref<boolean> = ref(false)
    const exemploCadastraConsumidor: Ref<any> = ref({
      "consumidor": {
        "tipoPessoa": "PF",
        "pessoaFisica": {
          "cpf": "",
          "nome": "",
          "nascimento": "",
          "sexo": ""
        }
      },
      "dadosComplementaresConsumidor": {
        "emails": [
          {
            "email": ""
          }
        ],
        "telefones": [
          {
            "ddd": "",
            "telefone": "",
            "tipoTelefone": 4
          }
        ]
      }
    })

    const exemploAberturaAtendimento: Ref<any> = ref({
      "veiculo": {
        "marca": {
          "codigo": null,
          "descricao": ""
        },
        "motor": {
          "codigo": null,
          "descricao": ""
        },
        "placa": "",
        "modelo": {
          "codigo": null,
          "descricao": ""
        },
        "renavam": null,
        "anoModelo": "",
        "tipoCambio": "",
        "tipoVeiculo": {
          "codigo": null,
          "descricao": ""
        },
        "chaveVeiculo": null,
        "numeroChassi": null,
        "tipoOdometro": "",
        "anoFabricacao": "",
        "tipoCombustivel": ""
      },
      "consumidor": {
        "tipoPessoa": "PF",
        "chaveAcesso": null,
        "pessoaFisica": {
          "cpf": "",
          "nome": "",
          "sexo": "",
          "nascimento": ""
        }
      },
      "atendimento": {
        "itens": [
          {
            "checkpoint": {
              "numeroCheckpoint": null,
              "codigoStatusCheckpoint": ""
            },
            "quantidade": null,
            "codigoGrupo": "",
            "codigoProduto": "",
            "valorUnitario": "",
            "descricaoProduto": "",
            "produtoAdicionalId": ""
          },
          {
            "checkpoint": {
              "numeroCheckpoint": null,
              "codigoStatusCheckpoint": ""
            },
            "quantidade": null,
            "codigoGrupo": "",
            "codigoProduto": "",
            "valorUnitario": "",
            "descricaoProduto": "",
            "produtoAdicionalId": ""
          },
          {
            "checkpoint": {
              "numeroCheckpoint": null,
              "codigoStatusCheckpoint": ""
            },
            "quantidade": null,
            "codigoGrupo": "",
            "codigoProduto": "",
            "valorUnitario": "",
            "descricaoProduto": "",
            "produtoAdicionalId": ""
          }
        ],
        "consumidor": "",
        "horaInicio": "",
        "checkpoints": [
          {
            "numeroCheckpoint": 1,
            "codigoStatusCheckpoint": "T"
          },
          {
            "numeroCheckpoint": 2,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 3,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 4,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 5,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 6,
            "codigoStatusCheckpoint": "T"
          },
          {
            "numeroCheckpoint": 7,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 8,
            "codigoStatusCheckpoint": "T"
          },
          {
            "numeroCheckpoint": 9,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 10,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 11,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 12,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 13,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 16,
            "codigoStatusCheckpoint": "-"
          },
          {
            "numeroCheckpoint": 17,
            "codigoStatusCheckpoint": "-"
          }
        ],
        "codigoCupom": "",
        "horaTermino": "",
        "tipoCampanha": "",
        "tipoAutomacao": null,
        "motivoDesconto": "",
        "dataAtendimento": "",
        "tipoAtendimento": "",
        "dataCancelamento": null,
        "statusAtendimento": null,
        "quilometragemAtual": "",
        "valorTotalDesconto": null,
        "veiculoAtendimento": null,
        "dataProximaTrocaOleo": "",
        "descricaoCancelamento": null,
        "valorTotalAtendimento": "",
        "dataAtendimentoReferencia": null,
        "numeroAtendimentoAutomotivo": null,
        "quilometragemProximaTrocaOleo": null,
        "numeroAtendimentoAutomotivoReferencia": null
      },
      "dadosComplementaresConsumidor": {
        "emails": [
          {
            "email": ""
          }
        ],
        "telefones": [
          {
            "ddd": "",
            "telefone": "",
            "tipoTelefone": 4
          }
        ]
      }
    })

    const exemploConfirmaPagamento: Ref<any> = ref({
      "placa": "",
      "dataAtendimento": "0000-00-00T00:00:00",
      "numeroAtendimentoAutomotivo": null
    })

    const loadings: Ref<any> = ref({
      loadingGetCredentials: false,
      loadingGetTokenRegistraPdv: false,
      loadingBuscaLoja: false,
      loadingRegistraTerminal: false,
      loadingGetTokenBackend: false,
      loadingBuscaConsumidor: false,
      loadingCadastraConsumidor: false,
      loadingConsultaOs: false,
      loadingAberturaAtendimento: false,
      loadingConfirmaPagamento: false,
    })


    const credentials = reactive<Credentials>({
      client_id_camp: '',
      client_secret_camp: '',
      grant_type: ''
    })
    const credentialsPdv: Ref<CredentialsPdv> = ref({
      client_id: '',
      client_secret: '',
      grant_type: 'client_credentials'
    })

    const registraTerminalInput: Ref<RegistraTerminalInput> = ref({
      codigoPontoVenda: null,
      codigoComponenteCorporativo: null,
      token: ''
    })


    async function registraTerminalPdv() {
      loadings.value.loadingRegistraTerminal = true

      try {
        const response = await Api.get('registraTerminalPdv', registraTerminalInput.value);
        registraTerminalResponse.value = response

        if (response.pdvsCredentials && response.pdvsCredentials.credentialsPdvs) {
          credentialsPdv.value.client_id = response.pdvsCredentials.credentialsPdvs[0].clientId
          credentialsPdv.value.client_secret = response.pdvsCredentials.credentialsPdvs[0].clientSecret
        }
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingRegistraTerminal = false
      }
    }

    function preencheRegistraTerminal(codAbadi: number, codComponente: number): void{
      registraTerminalInput.value.codigoComponenteCorporativo = codComponente;
      registraTerminalInput.value.codigoPontoVenda = codAbadi
    }

    async function getTokenBackendCamp() {
      loadings.value.loadingGetTokenBackend = true
      try {
        const response = await Api.post('getTokenBackendCamp', credentialsPdv.value);
        responseTokenBackendCamp.value = response
        if (response.access_token) {
          //não foi possivel gerar token
          tokenPdv.value = response.access_token
        } else {
          console.log("erro ao gerar token")
        }
        // console.log(token)
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingGetTokenBackend = false
      }
    }

    async function getCredentials() {
      loadings.value.loadingGetCredentials = true
      try {
        const { data } = await Api.get('getCredenciaisRegistraPdv');
        if (data) {
          credentials.client_id_camp = data.client_id_camp
          credentials.client_secret_camp = data.client_secret_camp
          credentials.grant_type = data.grant_type
        }
        loadings.value.loadingGetCredentials = false

      } catch (error) {
        console.error('Erro ao buscar as credenciais:', error);
      }finally {
        // console.log("caiu aqui")
        loadings.value.loadingGetCredentials = false
        // console.log(loadings.value.loadingGetCredentials)
      }
    }

    async function getDadosPdv() {
      loadings.value.loadingBuscaLoja = true
      try {
        const data = await Api.get('getDadosPdv', { cnpjPdv: cnpjPdv.value, token: tokenRegistraPdv.value });
        dadosPdv.value = data
        if(data.listaComponente && data.listaComponente.length > 0){
          showDadosPdv.value = true
        }else{
          showDadosPdv.value = false
        }
      } catch (error) {
        console.error('Erro ao buscar as dados pdv:', error);
      } finally {
        loadings.value.loadingBuscaLoja = false
      }
    }

    async function getTokenRegistraPdvFrontEnd() {
      loadings.value.loadingGetTokenRegistraPdv = true
      try {
        const response = await Api.post('getTokenRegistraPdvFrontEnd')
        responseTokenRegistraPdv.value = response
        tokenRegistraPdv.value = response.access_token
        registraTerminalInput.value.token = response.access_token
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingGetTokenRegistraPdv = false
      }
    }

    async function getConsumidor() {
      loadings.value.loadingBuscaConsumidor = true
      try {
        const response = await Api.get('getConsumidor', { cpfConsumidor: cpfConsumidor.value, token: tokenPdv.value })
        dadosConsumidor.value = response
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingBuscaConsumidor = false
      }
    }

    async function cadastraConsumidor() {
      loadings.value.loadingCadastraConsumidor = true
      try {
        const response = await Api.post('cadastraConsumidor', { dadosConsumidor: JSON.parse(dadosCadastraConsumidor.value), token: tokenPdv.value })
        responseCadastraConsumidor.value = response
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingCadastraConsumidor = false
      }
    }

    async function consultaOs() {
      loadings.value.loadingConsultaOs = true
      try {
        const response = await Api.get('consultaOs', { placa: placaParaBuscaOs.value, token: tokenPdv.value })
        responseConsultaOs.value = response
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingConsultaOs = false
      }
    }

    async function abreAtendimento() {

      loadings.value.loadingAberturaAtendimento = true
      try {
        const response = await Api.post('abreAtendimentoConecta', { dadosAtendimento: JSON.parse(dadosAtendimento.value), token: tokenPdv.value })
        responseAbreAtendimento.value = response
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingAberturaAtendimento = false
      }
    }

    async function confirmaPagamento() {
      loadings.value.loadingConfirmaPagamento = true
      try {
        const response = await Api.put('confirmaPagamentoConecta', { dadosConfirmaPagamento: JSON.parse(dadosConfirmaPagamento.value), token: tokenPdv.value })
        responseConfirmaPagamento.value = response
      } catch (error) {
        console.log(error)
      } finally {
        loadings.value.loadingConfirmaPagamento = false
      }
    }

    function copiaExemploCadastraConsumidor() {
      dadosCadastraConsumidor.value = JSON.stringify(exemploCadastraConsumidor.value, null, 2)
    }

    function copiaExemploAbreAtendimento() {
      dadosAtendimento.value = JSON.stringify(exemploAberturaAtendimento.value, null, 2)
    }

    function copiaExemploConfirmaPagamento() {
      dadosConfirmaPagamento.value = JSON.stringify(exemploConfirmaPagamento.value, null, 2)
    }


    onMounted(() => {
      getCredentials();
    })

    return {
      router,
      client_id,
      cnpjComponente,
      dadosPdv,
      credentials,
      credentialsPdv,
      cnpjPdv,
      getDadosPdv,
      registraTerminalInput,
      registraTerminalPdv,
      registraTerminalResponse,
      getTokenBackendCamp,
      getTokenRegistraPdvFrontEnd,
      getConsumidor,
      dadosConsumidor,
      cpfConsumidor,
      cadastraConsumidor,
      dadosCadastraConsumidor,
      consultaOs,
      placaParaBuscaOs,
      responseCadastraConsumidor,
      responseConsultaOs,
      dadosAtendimento,
      abreAtendimento,
      responseAbreAtendimento,
      dadosConfirmaPagamento,
      responseConfirmaPagamento,
      confirmaPagamento,
      loadings,
      copiaExemploCadastraConsumidor,
      copiaExemploAbreAtendimento,
      copiaExemploConfirmaPagamento,
      responseTokenRegistraPdv,
      tokenRegistraPdv,
      tokenPdv,
      showDadosPdv,
      preencheRegistraTerminal,
      loadingCredentialsGetTokenRegistra,
      responseTokenBackendCamp
    }
  },
});
